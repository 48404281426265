<template lang="pug">
SwiperBlock(:slidesData="seasonalBanners" :pagination="false")
  template(#heading)
    OmHeading.mb-6.font-size-3(h3) {{ $t('templateChooser.seasonal.upcomingSeasons') }}
  template(#link)
    OmLink.text-nowrap(primary withIconRight data-track-property="change:seeAllUpcoming" @click="onSeeAll({ name: 'seasonal-collection'})") {{ $t('templateChooser.seasonal.seeAll') }}
  template(#slide-component="{ name, img }")
    SeasonCard(
      :name="name"
      :season="{ name, img }"
      :to="getListingRoute(name)"
      @navigateWithFilterReset="$emit('navigateWithFilterReset', $event)"
    )
</template>

<script>
  import { mapGetters } from 'vuex';
  import SwiperBlock from '@/components/TemplateChooser/components/blocks/SwiperBlock.vue';
  import SeasonCard from '@/components/TemplateChooser/components/cards/SeasonCard.vue';

  export default {
    name: 'UpcomingSeasonsBanners',
    components: { SwiperBlock, SeasonCard },
    computed: {
      ...mapGetters('templateChooser', ['seasonalBanners']),
    },
    methods: {
      getListingRoute(seasonName) {
        return {
          name: 'seasonal-templates',
          params: { slug: seasonName },
        };
      },
      onSeeAll(allSeasonalLink) {
        this.$router.push(allSeasonalLink);
      },
    },
  };
</script>
